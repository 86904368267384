import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const ImageWrap = ({ image }) => {
  const gImage = getImage(image?.localFile?.childImageSharp.gatsbyImageData)
  if (gImage) {
    return <GatsbyImage image={gImage} alt='' />
  }
  return false
}

export default ImageWrap
