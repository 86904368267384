import React from 'react'
import BannerBlock from 'components/blocks/BannerBlock'

export default function MoreCollections({ content, allCollections }) {
  const moreCollections = content?.collections

  if (!moreCollections) return false
  moreCollections.forEach((collection) => {
    const matchingAllCollection = allCollections.find(
      (allCollection) => allCollection.slug === collection.slug
    )
    collection.bannerBlock = matchingAllCollection.bannerBlock
  })

  return (
    <>
      {moreCollections.map((collection, i) => (
        <BannerBlock key={i} content={collection.bannerBlock} />
      ))}
    </>
  )
}
