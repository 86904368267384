import React from 'react'
import { graphql } from 'gatsby'
import BannerBlock from 'components/blocks/BannerBlock'
import FeaturedCollection from 'components/FeaturedCollection'
import MoreCollections from 'components/MoreCollections'

export default function CollectionsPage({ data }) {
  const page = data.strapiCollectionsPage
  const allCollections = data.allStrapiCollection.nodes
  const { introBannerBlock, featuredCollection, moreCollections } = page
  return (
    <div className='page-collections'>
      <BannerBlock content={introBannerBlock} />
      <FeaturedCollection
        content={featuredCollection}
        allCollections={allCollections}
      />
      <MoreCollections
        content={moreCollections}
        allCollections={allCollections}
      />
    </div>
  )
}

export const pageQuery = graphql`
  query ($locale: String!) {
    strapiCollectionsPage(locale: { eq: $locale }) {
      title
      seo {
        title
        description
      }
      introBannerBlock {
        pretitle
        title
        excerpt
        link
        alignH
        alignV
        textColor
        image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      featuredCollection {
        featuredCollection {
          slug
        }
      }
      moreCollections {
        collections {
          slug
        }
      }
      moreBannersBlock {
        pretitle
        title
        excerpt
        link
        alignH
        alignV
        textColor
        image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
    allStrapiCollection(
      filter: { locale: { eq: $locale } }
      sort: { fields: order }
    ) {
      nodes {
        slug
        bannerBlock {
          pretitle
          title
          excerpt
          link
          alignH
          alignV
          textColor
          image {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          link
        }
        featuredCollectionImage {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        products {
          title
          excerpt
          image {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  }
`
